<template>
  <div>
    <informatie />
  </div>
</template>

<script>
import Informatie from "@/components/admin/Informatie.vue";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Informatie,
  },
  metaInfo: {
    title: "Kennisitems beheren| flexplekken in Nederland ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
