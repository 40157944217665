<template>
  <div>
    <v-container style="padding-top:4rem">
      <div style="margin-bottom:10px">
        <v-btn color="secondary" >
          Ga terug
        </v-btn>
      </div>
      <div style="margin-bottom:15px">
        <v-row>
          <v-col cols="auto">
            <v-dialog transition="dialog-top-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on"
                  >Kennis toevoegen</v-btn
                >
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark clas="text-center"
                    >Kennisitem toevoegen</v-toolbar
                  >
                  <v-card-text>
                    <div class="text-h2 pa-12">
                      <v-text-field
                        v-model="title"
                        label="Onderwerp"
                        outlined
                        clearable
                      ></v-text-field>

                      <v-textarea
                        v-model="info"
                        outlined
                        name="input-7-4"
                        label="Omschrijving"
                      ></v-textarea>

                      <v-select
                        v-model="kennisuser"
                        :items="items"
                        label="Zichtbaarheid aangeven"
                        outlined
                      ></v-select>
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Sluiten</v-btn>

                    <v-btn text @click="addKennisitem()">Opslaan</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
      </div>

      <v-expansion-panels class="mb-6">
        <v-expansion-panel
          v-for="kennis in kennisItems"
          :key="kennis.kennis_id"
        >
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            {{ kennis.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content
            >{{ kennis.info }}

            <div class="group">
              <!-- <v-btn class="button" dark small color="green">
                <v-icon>mdi-pencil</v-icon>
              </v-btn> -->

              <v-btn
                class="button"
                dark
                small
                color="red"
                @click="deleteKennisItem(kennis)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>


import api from "../api/api";
export default {

  data() {
    return {
      kennisuser: null,
      title: "",
      info: "",
      kennisItems: [],
      update: [],
      items: ["portaal", "Users", "Private"],
    };
  },
  mounted() {
    api.getKennisItems().then((data) => {
      this.kennisItems = data;
    });
  },
  methods: {
    resetForm() {
      this.title = "";
      this.info = "";
    },

    async deleteKennisItem(kennis) {
      await api.deleteKennisItem(kennis);
      this.resetForm();
      this.dialog = false;
      await this.api.getKennisItems();
    },

     async addKennisitem() {
      try {
        const data = {
       title: this.title,
          info: this.info,
          type: this.kennisuser,
        };

        const response = await api.addKennisitem(data);
        console.log(response);

            this.$swal.fire("Dankje!", "Aangemaakt", "success");
      } catch (error) {
        console.error(error);
     this.$swal.fire("Error!", "Er Is een foutmelding.", "error");
      }
    },

    goHome() {
      this.$router.push("/AdminPortaal");
    },
  },
};
</script>

<style scoped>
.button {
  margin: 5px;
}
.group {
  margin-top: 10px;
  float: right;
}
</style>
